//PS_CGAI_5.1 neecessary imports
import { useState } from "react";
import { securityKeyCheck } from "../AI Service/Api";
/**
 * PS_CGAI_5.2 to PS_CGAI_5.7 create an onchenge even to the textarea field
 * PS_CGAI_5.22 to PS_CGAI_5.26 get the values in onchege and pass to the parent Chatcomponent
 * @param {function from the parent to get the values} props
 * @returns
 */
export function CodeSnippetComponent(props) {
  const [error, seterror] = useState("");
  const [fileError, setfileError] = useState("");
  let obj = {
    Title: "",
    Code: "",
  };
//(props,"///////////");
console.log(props,"tadaaaaaaaaaa")
  const [codeSnippetValues, setcodeSnippetValues] = useState(obj);
  
  /**
   * PS_CGAI_5.8 to SQ_CGAI_2.19 pass the value to backend using securitykeycheck check any security/secret key in the code with set of regex in the backend
   * and if anything means show error key found if not show not fount
   * @param {event that is triggered in the textarea field} e
   */
  const codeCheck = async (e) => {
    try {
      let result = await securityKeyCheck({
        codeSnippet: JSON.stringify(e.target?.value.trim()),
      });
 
      
      if (
        result?.ResponseData ===
        "Security details detected saving code snippet is not allowed"
      ) {
        seterror(
          "Security details detected saving code snippet is not allowed"
        );
      } else {
        setcodeSnippetValues({
          ...codeSnippetValues,
          ["Code"]: e.target.value,
        });
        seterror("No security key found you can proceed");
      }
    } catch (error) {
  
    }
  };
  return (
    <div className="container-fluid">
      <div
        className="modal fade show"
        id="codesnippet-popup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered custom-codesnippet-width border-0">
          <div className="modal-content p-3 border-0 bg-color-4">
            <div className="modal-header border-0 pt-2">
              <h5 className="modal-title text-dark font-22 font-bold text-color-1">
                Code Snippet
              </h5>
              <button
                type="button"
                className="btn-close close-icon-filter"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  props.props.variable(false);
                  seterror("");
                  setcodeSnippetValues({
                    ...codeSnippetValues,
                    ["Title"]: "",
                    ["Code"]: "",
                  });
                }}
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-5 mb-4">
                  <input
                    type="text"
                    className="form-control font-14 font-medium theme-focus input-field custom-border-3 text-color-4 bg-transparent"
                    placeholder="Add title"
                    value={codeSnippetValues.Title}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 0 &&
                        e.target.value.length < 15
                      ) {
                        setfileError("");
                        setcodeSnippetValues({
                          ...codeSnippetValues,
                          ["Title"]: e.target.value,
                        });
                      } else {
                        setfileError(
                          "File name Should be withing 15 characters"
                        );
                      }
                    }}
                  />
                  <span
                    className="mandatory-star font-14 font-medium mb-0"
                    style={{ color: "red" }}
                  >
                    {fileError}
                  </span>
                </div>
                <div className="col-md-12 mb-2">
                  <textarea
                    className="form-control font-14 font-medium textarea-sty theme-focus input-field custom-border-3 text-color-4 bg-transparent"
                    rows={8}
                    value={codeSnippetValues.Code}
                    onBlur={(e) => {
                      if (e.target.value !== "") {
                        seterror("wait for sometime to evaluate your code");
                        codeCheck(e);
                      }
                    }}
                    onChange={(e) => {
                      setcodeSnippetValues((pre) => ({
                        ...pre,
                        ["Code"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div>
                  <span
                    className="mandatory-star font-14 font-medium mb-0"
                    style={{ color: "red" }}
                  >
                    {error}
                  </span>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn custom-outline-btn  font-14 px-4 py-2 me-3 font-medium"
                // data-bs-dismiss="modal"
                onClick={(e) => {
                  props.props.variable(false);
                  seterror("");
                  setcodeSnippetValues({
                    ...codeSnippetValues,
                    ["Title"]: "",
                    ["Code"]: "",
                  });
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn theme-color-bg text-white font-14 px-4 py-2 font-medium theme-focus"
                disabled={error !== "No security key found you can proceed"}
                onClick={() => {
                  if (error === "No security key found you can proceed") {
                    props.props.fun(codeSnippetValues);
                    props.props.variable(false);
                  }
                }}
              >
                Insert
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
