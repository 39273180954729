import React, { useState, useRef, useEffect } from 'react';
 
import {getBindParams} from "../AI Service/Api";

const CustomDropdown = ({ modelData,handleModelChange,isImage,setPromptMessageData,promptMessageData,isManagePrompt,selectedModel,handleOptionClick,isOpen,setIsOpen,description,setDescription,selectedOption,setSelectedOption}) => {
  // const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(null);
  // const [description, setDescription] = useState('');
  const dropdownRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoverStyles, setHoverStyles] = useState({});
  console.log(modelData,"modeldata")
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [bodyClasses, setBodyClasses] = useState([]);
  const [defaultModelNameFromEnv, setDefaultModelNameFromEnv] = useState("")

  useEffect(()=>{
 
  },[selectedModel])

  const fetchDefaultModelName = async () => {
 
    try {
      let response = await getBindParams()
      console.log(response.data.defaultModel[0]["default_model_name"], "popopopop")
      setDefaultModelNameFromEnv(response.data.defaultModel[0]["default_model_name"])
 
    }
    catch (error) {
      //("logging error")
    }
  }


  useEffect(() => {
    fetchDefaultModelName()
  }, [])



  useEffect(() => {
    // Function to update the state with current body classes
    const updateBodyClasses = () => {
      setBodyClasses(Array.from(document.body.classList));
    };

    // Initial update
    updateBodyClasses();

    // Create a MutationObserver to watch for changes in body's class attribute
    const observer = new MutationObserver(updateBodyClasses);

    // Start observing
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    // Cleanup function
    return () => observer.disconnect();
  }, []); // Empty dependency array, we only set this up once

  useEffect(() => {
    console.log(bodyClasses, "say hello");
  }, [bodyClasses]); 
  

  const handleOptionHover = (description, index, event) => {
    const rect = event.target.getBoundingClientRect();
    const viewportOffset = {
      top: rect.top,
      left: rect.left,
    };

    setDescription(description);
    //
    setHoveredIndex(index);
    setHoverStyles({
      top: `${viewportOffset.top + rect.height/3}px`,
      left: `${viewportOffset.left + 1.75*rect.width}px`,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setDescription('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  return (
    <div className="custom-dropdown theme-focus" ref={dropdownRef}>
      <div
  className="input-field font-14 font-regular border-radius-3 custom-border-3 text-color-4 bg-color theme-focus"
  onClick={toggleDropdown}
  style={{ position: "relative" }}
  tabIndex={0} /* Makes the div focusable */
>
  { selectedOption ? selectedOption.displayName :selectedModel=="Select"?"Select":selectedModel?selectedModel:isImage ? "Vision Model" : defaultModelNameFromEnv}
{Array.isArray(modelData)&&(
  <span className={`bg-color arrow ${isOpen ? 'up' : 'down'} text-color-4`}
  style={{ ...(bodyClasses[1] === "dark-theme" ? { top:"10px",position: "absolute",right: "10px", border: "solid white", borderWidth: "0 2px 2px 0" } : {top:"10px",position: "absolute",right: "10px"}) }}>
</span>
)}
 
</div>
      {isOpen && Array.isArray(modelData)&& (
        <ul className="dropdown-list bg-color border-radius-3  text-color-2 hide-scrollbar">
          {modelData
            ?.filter(value =>
              value.modelName !== "Test" &&
              value.modelName !== "Assistants API" &&
              value.modelName !== "GPT-vision-Asami" &&
              value.modelName !== "TestModel" &&
              value.modelName !== ""
            )
           
            ?.map((option, index) => (
              selectedModel !== option.displayName ?
                <div key={index}>
              <li
                className="dropdown-list-item bg-color text-color-4"
                onClick={() => {handleOptionClick(option);handleModelChange(option)}}
                onMouseEnter={(event) => handleOptionHover(option.modelDescription, index, event)}
                onMouseLeave={() => {
                  setDescription('');
                  setHoverStyles({});
                }}
              >
                {option.displayName}
                {hoveredIndex === index && description && (
                  <div
                    className="option-description"
                    style={{
                      ...hoverStyles,
                      position: 'fixed',
                       // Make the description fixed
                    }}
                  >
                    {description}
                  </div>
                )}
              </li>
                </div>:<></>
            ))
          }
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
