// PS_AAI_1.0 import required packages
import React, { useState, useEffect } from 'react';
import { fetchPromptEmj, insertGetFeedBack } from '../AI Service/Api';

// PS_AAI_1.5 -create component for feebackform and decalre state variable
const FeedBackForm = () => {
  const [promptDropDown, setPromptDropDown] = useState([]);
  const [emojiData, setEmojiData] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [selectedEmojis, setSelectedEmojis] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmojiId, setSelectedEmojiId] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  // PS_AAI_1.10 In useeffect call the getPromptEmj function
  useEffect(() => {
    getPromptEmj();
    checkAndOpenModal();
  }, []);
  // PS_AAI_1.11 to PS_AAI_1.27 getPromptEmj function used to getPrompt and emoji url 
  function emoji(url){
    console.log(url,"trrrrrrrrrrrrrr")
    //neutral-emoji.svg
    let adjustedUrl = ""
    if (url.includes('neutral-emoji.svg')) {
      // Adjust the URL to use 'user-profile-storage'
      adjustedUrl = 'images-new/neutral-emoji.svg';
    }
    else if(url.includes('happy-emoji.svg')){
      adjustedUrl = 'images-new/happy-emoji.svg';
    }
    else{
      adjustedUrl = 'images-new/sad-emoji.svg'
    }
    return adjustedUrl
  }
  const getPromptEmj = async () => {
    // 
    try {
      const userResponse = await fetchPromptEmj({});
      //(userResponse)
      console.log(userResponse,"user")
      setPromptDropDown(userResponse.PromptData || []);
      setEmojiData(userResponse.FeedbackReaction || []);
      console.log(emojiData,"new",userResponse.FeedbackReaction,"old")
    } catch (err) {
      setError('Error fetching prompt and emoji');
    }
  };
// check the it wednesday 
  const checkAndOpenModal = () => {
    try {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const feedbackSubmittedFromStorage = localStorage.getItem('feedbackSubmitted');
      if (dayOfWeek === 3  && !feedbackSubmitted && feedbackSubmittedFromStorage !== 'true') {
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error checking and opening modal:", error);
    }
  };
// PS_AAI_1.40 - 56 - Onclick function call insertFeedback to insert feedbacks in Dat abase to call api with request body
  const insertFeedback = async () => {
     setFeedbackSubmitting(true)
    try {
      if (selectedPrompt && selectedEmojis && userDescription) {
        const requestBody = {
          PromptID: selectedPrompt,
          ReactionID: selectedEmojis,
          description: userDescription,
        };
        const response = await insertGetFeedBack(requestBody);
        //(response.Success);
        if (response.Success === true) {
          setFeedbackSubmitted(true); 
          localStorage.setItem('feedbackSubmitted', 'true'); 
          handleCancel();
          setError(null);
        } else {
          setError('Something went wrong while submitting feedback');
        }
      } else {
        setError('Please fill out all required fields');
      }
    } catch (err) {
      setError('Something went wrong');
    } finally {
      setFeedbackSubmitting(false)
    }
  };

  // PS_AAI_1.30 if user Selected prompt any value update the selectedPrompt
  const handlePrompt = (event) => {
    setSelectedPrompt(event.target.value);
  };
  // PS_AAI_1.33 if user Selected emoji any value update the selectedEmoji
  const handleEmojiClick = (emojiid,index) => {
    setSelectedEmojis(emojiid);
    setSelectedEmojiId(index)
  };
  // PS_AAI_1.36 if user Selected emoji any value update the selectedEmoji
  const handleDescriptionChange = (event) => {
    setUserDescription(event.target.value);
  };
  // PS_AAI_1.38 To insert the feedback to insertFeedback function
  const handleinsertFeedback = () => {
    insertFeedback();
  };

  const handleCancel = () => {
    setSelectedPrompt('');
    setUserDescription('');
    setSelectedEmojis('');
    setShowModal(false);
  };

  return (
    <div>
      <style>{`.modal-backdrop.show { opacity: 0.5; }`}</style>
      <div className="container-fluid">
        {showModal && <div className="modal-backdrop show"></div>}
        <div className={`modal fade ${showModal ? 'show' : ''}`} id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true" style={{ display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered feedback-popup border-0">
            <div className="modal-content p-4 border-0">
              <div className="modal-header border-0 pt-2 pb-2 px-0">
                <div>
                  <h5 className="modal-title text-dark font-22 font-bold">Feedback</h5>
                  <p className="font-medium font-15 color-grey-v7">Tell us what you think about prompt and help us improve</p>
                  {error && <span style={{ color: 'red' }}>{error}</span>}
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel} />
              </div>
              <div className="modal-body pt-2 px-0 pb-0">
                <div className="row">
                  <div className="col-md-7">
                    <div className="mb-4">
                      <label htmlFor="from" className="form-label label-color font-15 font-semibold">Select Prompt <span style={{ color: 'darkred' }}>*</span></label>
                      <select className="form-select input-field font-14 font-regular border-radius-3" value={selectedPrompt} onClick={(e)=>e.currentTarget.classList.toggle('open')} onChange={handlePrompt}>
                        <option selected>Select</option>
                        {promptDropDown && promptDropDown.length !== 0 ? (
                          promptDropDown.map((prompt, index) => (
                            <option key={index} value={prompt.Prompt_id}>{prompt.Prompt_name}</option>
                          ))
                        ) : (
                          <option>No option</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor className="form-label label-color font-15 font-semibold d-block">What do you think of this tool ?<span style={{ color: 'darkred' }}>*</span></label>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    {emojiData && emojiData.length !== 0 ? (
                      emojiData.map((url, index) => (
                        <button key={index} type="button"   className={`emoji-active  align-items-center me-5 position-relative ${
                          selectedEmojiId === index ? 'selected' : ''
                        }`} onClick={() => handleEmojiClick(url.emoji_id,index)}><img src={emoji(url.blob_url)} alt="sad-emoji" /></button>
                      ))
                    ) : (
                      <p>No Emoji Found <option value=""></option></p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="from" className="form-label label-color font-15 font-semibold">Do you have any thoughts you'd like to share?<span style={{ color: 'darkred' }}>*</span></label>
                  <textarea rows={4} className="form-control resize-none input-field" placeholder="Enter your thoughts" id="floatingTextarea" value={userDescription} onChange={handleDescriptionChange} />
                </div>
              </div>
              <div className="modal-footer border-0 gap-3 px-0">
                {/* <button type="button" className="btn btn-outline-secondary font-14 px-4 m-0 font-medium" data-bs-dismiss="modal" onClick={handleCancel}>Cancel</button> */}
                <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4 m-0" onClick={handleinsertFeedback}>{feedbackSubmitting ? "Sending..." : "Send"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBackForm;