import { useMsal, useIsAuthenticated,useAccount } from "@azure/msal-react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isAuth } from "../Auth/authConfig";
import devConfig from "../config/env";
import axios from "axios";
export function Login({myProp}) {
  const { instance,accounts  } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const account = useAccount(accounts[0] || null);
  console.log(account,"account for mervin")
  // const [show, setShow] = useState(true);
 
  async function handleLogin() {
    let route = "/chat"; // Default route
    let accountResponse;
  
    try {
      // Step 1: Get the token
      if (!account) {
        console.log("No account available, initiating login...");
        accountResponse = await instance.loginPopup({
          scopes: ["https://graph.microsoft.com/User.Read"],
        });
      } else {
        try {
          accountResponse = await instance.acquireTokenSilent({
            account,
            scopes: ["User.Read"],
          });
        } catch (silentError) {
          // If silent token acquisition fails, fall back to interactive method
          console.log("Silent token acquisition failed, trying interactive method");
          accountResponse = await instance.acquireTokenPopup({
            account,
            scopes: ["User.Read"],
          });
        }
      }
  
      // Step 2: Check leadership status
      const checkLeadership = async (groupId) => {
        try {
          const response = await axios.get(
            `https://graph.microsoft.com/v1.0/groups/${groupId}/transitiveMembers/${accountResponse.account.localAccountId}`,
            {
              headers: {
                Authorization: `Bearer ${accountResponse.accessToken}`,
              },
            }
          );
          return response.status === 200;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Token might be expired, try to refresh
            accountResponse = await instance.acquireTokenSilent({
              account: accountResponse.account,
              scopes: ["User.Read"],
              forceRefresh: true
            });
            // Retry the request with the new token
            const retryResponse = await axios.get(
              `https://graph.microsoft.com/v1.0/groups/${groupId}/transitiveMembers/${accountResponse.account.localAccountId}`,
              {
                headers: {
                  Authorization: `Bearer ${accountResponse.accessToken}`,
                },
              }
            );
            return retryResponse.status === 200;
          }
          return false;
        }
      };
  
      const isInLeadershipDL = await checkLeadership(devConfig?.roleConfig?.LeadershipDL);
      if (isInLeadershipDL) {
        route = "/dashboard";
      } else {
        const isInLeadershipGroup = await checkLeadership(devConfig?.roleConfig?.LeadershipGroup);
        if (isInLeadershipGroup) {
          route = "/dashboard";
        }
      }
  
      // Step 3: Redirect based on determined route
      if (isAuth([accountResponse.account])) {
        history.push(route);
        localStorage.setItem("login", "true");
        console.log(localStorage.getItem("login"));
        window.location.reload()
      }
    } catch (error) {
      console.error("Error in handleLogin:", error);
      // If all else fails, fall back to redirect method
      const request = {
        scopes: ["https://graph.microsoft.com/User.Read"],
        redirectStartPage: window.location.origin + "/chat",
        redirectUri: window.location.origin + "/chat",
      };
      localStorage.setItem("login", "true");
      await instance.loginRedirect(request);
    }
  }
  // useEffect(() => {
  // if (isAuthenticated) {
  // }
  // }, [isAuthenticated]);
  const handleHeading1Click = () => {
    // Call the global function defined in index.html
    // window.skipAnimationAndShowHeading2();
  };
  return (
    <>
        <div className="container-fluid" style={{backgroundColor:"white"}}>
        <div className="row">
          <video autoPlay muted loop id="myVideo" className="position-absolute top-0 start-0 end-0 bottom-0  w-100 intro-video" style={{zIndex: 9, height: '100dvh', objectFit: 'cover'}}>
            <source src="videos/asami-intro.mp4" type="video/mp4" />
          </video>
          <div className="col-md-6 position-relative d-none d-lg-block" id="static-login">
            <video autoPlay muted loop id="sideVideo" className="position-absolute video-loop">
              <source src="videos/Side Anime.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="w-100 d-flex justify-content-center align-items-center flex-column gap-5" style={{height: '100dvh'}}>
              <img src="videos\Logo yy.gif" style={{height:"60px",width:"150px"}} alt="asami-logo" className="d-block" />
              <button className="btn grad-btn font-semibold"   onClick={() => {
                handleLogin();
              }}>Meet Asami</button>
              <p className="position-absolute font-16 font-regular landing-page-edit" style={{bottom:"10px"}}>For issues or concerns kindly reach <a href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}" className="them-link text-decoration-none">AsamiSupportCrew@avasoft.com</a>
              </p></div>
          </div>
        </div>
      </div>
    </>
  );
}