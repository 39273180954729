import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function PopUpComponent({
  text,
  noFunction,
  yesFunction,
  promptID,
  errorMessage,
  id,
  taskID, 
  setShowPopup
}) {

  const history = useHistory()
  useEffect(() => {
      console.log(text,noFunction,"text,noFunction")
  }, [])

  const getDisplayText = () => {
    if (errorMessage) {
      return errorMessage;
    } else if (taskID) {
      return `Need KG?`;
    } else {
      return `Are you sure you want to ${text ? text : ""}?`;
    }
  };
  
  return (
    <div>
      <div
        className="modal fade show"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered custom-logout-width border-0">
          <div className="modal-content p-3 border-0 bg-color-4">
            <div className="modal-header border-0 pt-2 pb-3">
              <button
                type="button"
                className="btn-close close-icon-filter"
                onClick={() => {
                  if (promptID) {
                    noFunction.setShowPopup(false);
                    noFunction.setPromptIndex(null);
                  }
                  else if(taskID){
                    setShowPopup(false);
                  } 
                  else {
                    noFunction(false);
                  }
                }}
              />
            </div>
            <div className="modal-body pt-2">
              <div className="row">
                {/*Info section starts here*/}
                <div className="text-center">
                  <p className="mb-3 font-20 font-medium mt-2 text-color-2">
                  {getDisplayText()}
                  </p>
                </div>
                {/*Info section ends here*/}
              </div>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center pb-3">
              <button
                type="button"
                className="btn danger-bg btn-danger border-0 text-white font-14 font-medium px-4 me-2 py-2"
                id={id}
                onClick={(e) => {
                  promptID ? yesFunction(promptID) : yesFunction(e);
                }}
              >
                {errorMessage ? `Try again` : `Yes`}
              </button>
              {!errorMessage && (
                <button
                  type="button"
                  className="btn btn-outline-secondary  font-14 px-4 py-2 me-3 font-medium"
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    if (promptID) {
                      noFunction.setShowPopup(false);
                      noFunction.setPromptIndex(null);
                    } else {
                      noFunction(false);
                    }
                  }}
                >
                  No
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}